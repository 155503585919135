<template>
  <div>
    <b-modal
      ref="copy_order"
      id="copy_order"
      title="Копировать заказ"
      size="lg"
      no-close-on-backdrop
      no-enforce-focus
      @show="getData"
      @shown="
        detectStartTime,
          $store.commit('caller/UPDATE_IS_ORDER_MODAL_OPENED_STATE', true)
      "
      @hidden="
        detectEndTime,
          $store.commit('caller/UPDATE_IS_ORDER_MODAL_OPENED_STATE', false)
      "
      content-class="order-content"
    >
      <b-row>
        <b-col cols="3">
          <label for="">Подразделение</label>
          <select
            @input="getTariffs($event.target.value)"
            :disabled="
              (editOrder.status_id == 10 || editOrder.status_id == 9) &&
              positionId == 1
                ? true
                : false
            "
            v-model="editOrder.division_id"
            class="form-control form-control-sm"
          >
            <option
              v-for="division in orderData.divisions"
              :key="division.id"
              :value="division.id"
            >
              {{ division.name }}
            </option>
          </select>
        </b-col>
        <b-col cols="2" class="mt-1 p-0">
          <div class="custom-control custom-checkbox">
            <input
              :disabled="
                (editOrder.status_id == 10 || editOrder.status_id == 9) &&
                positionId == 1
                  ? true
                  : false
              "
              @change="notIssued, getTariffs(this.editOrder.division_id)"
              v-model="selected"
              value="A"
              type="radio"
              class="custom-control-input"
              name="vv"
              id="customCheck1"
            />
            <label class="custom-control-label" for="customCheck1"
              >Автоназначение</label
            >
          </div>
          <div class="custom-control custom-checkbox">
            <input
              :disabled="
                (editOrder.status_id == 10 || editOrder.status_id == 9) &&
                positionId == 1
                  ? true
                  : false
              "
              @change="notIssued, getTariffs(this.editOrder.division_id)"
              v-model="selected"
              value="B"
              type="radio"
              name="vv"
              class="custom-control-input"
              id="customCheck2"
            />
            <label class="custom-control-label" for="customCheck2"
              >Не оформлен</label
            >
          </div>
        </b-col>
        <b-col cols="3" class="mt-1">
          <b-button
            variant="outline-primary"
            class="btn-icon mr-1"
            v-b-modal.memosCreateModal
            v-hotkey="{ 'alt+C': openMemosForm }"
            v-b-tooltip.hover.bottom="'Служебная записка'"
          >
            <feather-icon icon="FileTextIcon" />
          </b-button>
          <b-button
            variant="outline-primary"
            class="btn-icon"
            v-b-modal.driver_questionnaire
            v-b-tooltip.hover.bottom="'Анкета водителя'"
          >
            <feather-icon icon="TruckIcon" />
          </b-button>
          <b-dropdown id="dropdown-1" variant="link" size="sm">
            <template #button-content>
              <b-button
                variant="outline-primary"
                class="btn-icon mr-1"
                v-b-tooltip.hover.bottom="'Звонить'"
              >
                <feather-icon icon="PhoneIcon" />
              </b-button>
            </template>
            <b-dropdown-item :disabled="!hasPerformer" @click="callAndConnect()"
              >Соединить</b-dropdown-item
            >
            <b-dropdown-item :disabled="!hasPerformer" @click="callDriver()"
              >Водителю</b-dropdown-item
            >
            <b-dropdown-item @click="callClient()">Клиенту</b-dropdown-item>
            <b-dropdown-item @click="callClientToDopPhone()"
              >Клиенту на доп. номер</b-dropdown-item
            >
          </b-dropdown>
        </b-col>
      </b-row>
      <b-row class="mt-1">
        <b-col cols="7">
          <div class="input-group">
            <b-form-input
              @keyup.enter="getTariffs(this.editOrder.division_id)"
              v-mask="'############'"
              :disabled="
                (editOrder.status_id == 10 || editOrder.status_id == 9) &&
                positionId == 1
                  ? true
                  : false
              "
              size="sm"
              type="text"
              v-model="editOrder.phone"
              placeholder="Номер телефона"
            />
            <b-form-input
              @keyup.enter="getTariffs(this.editOrder.division_id)"
              v-mask="'############'"
              :disabled="
                (editOrder.status_id == 10 || editOrder.status_id == 9) &&
                positionId == 1
                  ? true
                  : false
              "
              size="sm"
              type="text"
              v-model="editOrder.dop_phone"
              placeholder="Доп.номер"
            />
            <b-form-input
              :disabled="
                (editOrder.status_id == 10 || editOrder.status_id == 9) &&
                positionId == 1
                  ? true
                  : false
              "
              size="sm"
              placeholder="Никнейм"
            />
          </div>
        </b-col>
        <b-col cols="5" class="d-flex justify-content-end mb-1/2">
          <label class="d-flex align-items-center">
            <input
              :disabled="
                (editOrder.status_id == 10 || editOrder.status_id == 9) &&
                positionId == 1
                  ? true
                  : false
              "
              type="checkbox"
              name=""
              class="cursor-pointer mr-1/2"
              @input="Intime"
              v-model="editOrder.for_time"
            />
            <span class="mr-1/2" style="line-height: 1">На время</span>
            <date-picker
              v-model="editOrder.date_time"
              :disabled="!editOrder.for_time"
              v-mask="'##:## ##-##-####'"
              type="datetime"
              :disabled-date="disabledDates"
              :disabled-time="disabledTimes"
              format="HH:mm DD-MM-YYYY"
              :value-type="'YYYY-MM-DD HH:mm'"
              placeholder="HH:mm DD-MM-YYYY"
              @input-error="spellDay"
            ></date-picker>
          </label>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="7">
          <div class="address">
            <h4>Адрес подачи</h4>
            <b-row>
              <b-col cols="12" class="mb-1/2">
                <search-address-v-select
                  :disabled="
                    (editOrder.status_id == 10 || editOrder.status_id == 9) &&
                    positionId == 1
                  "
                  @input="
                    (editOrder.from_address = $event),
                      getTariffs(this.editOrder.division_id)
                  "
                  :value="editOrder.from_address"
                  :defaultAddresses="defaultAddresses"
                ></search-address-v-select>
              </b-col>
              <b-col cols="12" class="mb-1/2">
                <label for=""> Инфо о встрече</label>
                <b-input
                  :disabled="
                    (editOrder.status_id == 10 || editOrder.status_id == 9) &&
                    positionId == 1
                      ? true
                      : false
                  "
                  size="sm"
                  autocomplete="off"
                  v-model="editOrder.meeting_info"
                  placeholder=""
                />
              </b-col>
            </b-row>
            <h4>Адрес маршрута</h4>
            <b-row>
              <b-col cols="12">
                <draggable v-model="editOrder.to_addresses">
                  <b-col
                    v-for="(to_address, id) in editOrder.to_addresses"
                    :key="id"
                    class="d-flex mb-1/2"
                    cols="12"
                  >
                    <b-button
                      :disabled="
                        (editOrder.status_id == 10 ||
                          editOrder.status_id == 9) &&
                        positionId == 1
                          ? true
                          : false
                      "
                      size="sm"
                      variant="outline-primary"
                      class="btn-icon mr-1/2"
                      v-if="id === 0"
                      @click="routePush"
                      v-hotkey="{ 'alt+insert': routePush }"
                    >
                      <feather-icon icon="PlusIcon" />
                    </b-button>
                    <b-button
                      :disabled="
                        (editOrder.status_id == 10 ||
                          editOrder.status_id == 9) &&
                        positionId == 1
                          ? true
                          : false
                      "
                      size="sm"
                      variant="outline-primary"
                      class="btn-icon mr-1/2"
                      v-else
                      @click="routeMinus(id)"
                    >
                      <feather-icon icon="MinusIcon" />
                    </b-button>
                    <search-address-v-select
                      class="w-100"
                      :disabled="
                        (editOrder.status_id == 10 ||
                          editOrder.status_id == 9) &&
                        positionId == 1
                      "
                      @input="
                        (editOrder.to_addresses[id] = $event),
                          getTariffs(this.editOrder.division_id)
                      "
                      :value="editOrder.to_addresses[id]"
                      :defaultAddresses="defaultAddresses"
                    ></search-address-v-select>
                  </b-col>
                </draggable>
              </b-col>
              <b-col cols="6">
                <label for="">Доп.инфо для водителя:</label>
                <b-form-textarea
                  :disabled="
                    (editOrder.status_id == 10 || editOrder.status_id == 9) &&
                    positionId == 1
                      ? true
                      : false
                  "
                  v-model="editOrder.info_for_drivers"
                  placeholder=""
                />
              </b-col>
              <b-col cols="6">
                <label for="">Доп.инфо для оператора:</label>
                <b-form-textarea
                  :disabled="
                    (editOrder.status_id == 10 || editOrder.status_id == 9) &&
                    positionId == 1
                      ? true
                      : false
                  "
                  v-model="editOrder.info_for_operator"
                  placeholder=""
                />
              </b-col>
            </b-row>
          </div>
        </b-col>
        <b-col cols="5">
          <div class="info-container">
            <h4>Тип заказа</h4>

            <b-row>
              <b-col class="d-flex justify-content-between mb-1/2" cols="12">
                <b-form-radio
                  @change="getTariffs(this.editOrder.division_id)"
                  :disabled="
                    (editOrder.status_id == 10 || editOrder.status_id == 9) &&
                    positionId == 1
                      ? true
                      : false
                  "
                  name="some-radios"
                  v-for="order_type in orderData.order_types"
                  :key="order_type.id"
                  :value="order_type.id"
                  v-model="editOrder.order_type_id"
                >
                  {{ order_type.name }}
                </b-form-radio>
              </b-col>
              <b-col cols="12" class="mb-1/2">
                <b-row>
                  <b-col>
                    <label for="priceInCity">Город:</label>
                    <b-form-input
                      size="sm"
                      id="priceInCity"
                      v-model="newOrderData.distance_in_city"
                      disabled
                    ></b-form-input>
                  </b-col>
                  <b-col class="ml">
                    <label for="priceInterCity">Межгород: </label>
                    <b-form-input
                      size="sm"
                      id="priceInterCity"
                      v-model="newOrderData.distance_inter_city"
                      disabled
                    >
                    </b-form-input>
                  </b-col>
                  <b-col class="ml">
                    <label for="distanceInCity">Холостой</label>
                    <b-form-input
                      size="sm"
                      id="distanceInCity"
                      v-model="newOrderData.free_km"
                      disabled
                    >
                    </b-form-input>
                  </b-col>
                </b-row>
                <label for="">Тариф</label>
                <select
                  @change="changeTariff, getTariffs(this.editOrder.division_id)"
                  :disabled="
                    (editOrder.status_id == 10 || editOrder.status_id == 9) &&
                    positionId == 1
                      ? true
                      : false
                  "
                  v-model="editOrder.tariff_id"
                  class="form-control form-control-sm"
                >
                  <option
                    v-for="tariff in tariffsList"
                    :key="tariff.id"
                    :value="tariff.id"
                  >
                    {{ tariff.name }}
                  </option>
                </select>
              </b-col>
              <b-col cols="12">
                <customSelectAllowance
                  @GetAllowances="GetAllowances"
                  :editOrder="editOrder.allowances"
                  :options="allowances"
                />
              </b-col>
              <b-col cols="12">
                <b-row class="mt-2 mb-1">
                  <b-col>
                    <input
                      type="checkbox"
                      @click="toggleChecked"
                      v-model="checked"
                    />
                    Безнал
                  </b-col>
                  <b-col>
                    <input
                      type="text"
                      :disabled="checked"
                      v-model="editOrder.bonus_price"
                    />
                  </b-col>
                </b-row>
                <div class="bonus" v-if="client_login">
                  <p>{{ client_login }}</p>
                  <p>{{ editOrder.phone }}</p>
                  <p>{{ client_bonus_balance }}</p>
                </div>
                <h4>
                  Надбавки:
                  <span
                    class="text-success"
                    v-for="allowance in editOrder.allowances"
                    :key="allowance.name"
                    >{{ allowance.name + ", " }}</span
                  >
                </h4>
                <h4>
                  Цена: <b class="text-danger">{{ editOrder.price }}</b> смн.
                </h4>
                <b-row>
                  <b-col>
                    <h4>
                      Итого:
                      <b class="text-danger">{{ editOrder.price }}</b> смн.
                    </h4>
                  </b-col>
                  <b-col>
                    <h4 style="text-align: right">
                      <b class="text-danger">{{ amount_cash }}</b> смн.
                    </h4>
                  </b-col>
                </b-row>
                <h4>
                  Растояние:
                  <b class="text-primary">{{ editOrder.distance }}</b> км.
                </h4>
                <b-button
                  variant="outline-primary"
                  @click="ableDisableInfoModal"
                  >Подробности</b-button
                >

                <div class="info-modal__interior" v-if="info_modal == true">
                  <b-row class="mb-1">
                    <b-col class="info-modal__tariff" cols="2">Тариф</b-col>
                    <b-col>
                      <select
                        @change="
                          changeTariff, getTariffs(this.editOrder.division_id)
                        "
                        v-model="newOrder.tariff_id"
                        class="form-control form-control-sm"
                      >
                        <option
                          v-for="tariff in tariffsList"
                          :key="tariff.id"
                          :value="tariff.id"
                        >
                          {{ tariff.name }}
                        </option>
                      </select>
                    </b-col>
                  </b-row>
                  <b-row class="info-modal__title"> Городской </b-row>
                  <div class="info-modal__text">
                    Поездки внутри города от<span>
                      {{ tariff_info.min_price }} смн.</span
                    >
                    <div>
                      В стоимость поездки входят <span>3</span> км дороги, после
                      <span>{{ tariff_info.price_km_city }} смн/км</span>.
                    </div>
                  </div>
                  <b-row class="info-modal__title"> Межгород </b-row>
                  <div class="info-modal__text">
                    Поездки вне города от
                    <span> {{ tariff_info.min_price }} смн.</span>
                    <div>
                      В стоимость поездки входят <span>3</span> км дороги, после
                      <span> {{ tariff_info.price_km_intercity }} смн/км</span>.
                    </div>
                  </div>
                  <b-row class="info-modal__title"> Надбавки </b-row>
                  <div class="info-modal__allowances">
                    <ul
                      v-for="allowance in tariff_info.allowances"
                      :key="allowance.name"
                    >
                      <li>{{ allowance.name }} - {{ allowance.price }} смн.</li>
                    </ul>
                  </div>
                  <div class="info-modal__text">
                    <b>Бесплатное ожидание</b> -
                    <span
                      >{{
                        tariff_info.free_waiting_of_client_in_minute
                      }}
                      мин.</span
                    >
                    , после
                    <span>{{ tariff_info.price_hour }}</span> дирам/мин..
                  </div>
                </div>
              </b-col>
            </b-row>
          </div>
          <div class="mt-2">
            <div v-if="editOrder.performer">
              <div>
                {{
                  "Водитель: " +
                  editOrder.performer.first_name +
                  " " +
                  editOrder.performer.last_name
                }}
              </div>
              <div>
                {{ "Логин водителя: " + editOrder.performer.login }}
              </div>
              <div v-if="editOrder.performer.transport">
                {{
                  "Автомобиль: " +
                  editOrder.performer.transport.model +
                  " " +
                  "(" +
                  editOrder.performer.transport.car_number +
                  ")"
                }}
              </div>
            </div>
            <div v-else>Водитель не назначен</div>
          </div>
        </b-col>
      </b-row>
      <template #modal-footer>
        <button
          :disabled="submitButtonDisabled"
          @click="addOrder"
          class="btn btn-primary btn-md"
        >
          Добавить
        </button>
        <button
          @click="$refs['copy_order'].hide()"
          class="btn btn-secondary btn-md"
        >
          Отмена
        </button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import vSelect from "vue-select";
import customSelectAllowance from "../../customSelect/customSelectAllowance.vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import customSelectEdit from "../../customSelect/customSelectEdit.vue";

import draggable from "vuedraggable";
import { mask } from "vue-the-mask";
import SearchAddressVSelect from "../../searchAddressVSelect.vue";

export default {
  directives: { mask },
  components: {
    vSelect,
    ToastificationContent,
    draggable,
    customSelectEdit,
    SearchAddressVSelect,
    customSelectAllowance,
  },
  props: ["editOrder", "orderData"],
  data() {
    return {
      selected: "",
      tariffsList: [],
      info_modal: false,
      tariff_info: [],
      positionId: JSON.parse(localStorage.getItem("userData")).role_id,
      allowancesName: [],
      indate: false,
      addresses: [],
      defaultAddresses: [],
      formTime: {
        form_name: null,
        start_time: null,
        end_time: null,
      },
      newOrderData: {
        distance_inter_city: null,
        distance_in_city: null,
        free_km: null,
        price_in_city: null,
        price_inter_city: null,
      },
      newOrder: {
        division_id: 1,
        phone: undefined,
        dop_phone: undefined,
        auto_assignment: 1,
        not_issued: 0,
        for_time: 0,
        date_time: undefined,
        search_address: undefined,
        to_addresses: [{}],
        number_of_passengers: undefined,
        meeting_info: undefined,
        info_for_operator: "",
        info_for_drivers: "",
        order_type_id: 1,
        tariff_id: 1,
        price: 0,
        distance: 0.0,
        allowances: [],
      },
      allowances: [],
      iframeEventListener: null,
      first_address: [],
      searchTimeout: null,
      submitButtonDisabled: false,
      memoData: undefined,
      currentDay: undefined,
      checked: false,
      amount_cash: 0,
      client_login: null,
      client_bonus_balance: 0,
    };
  },
  updated() {
    if (this.editOrder.search_address_id == null) {
      this.selected = "B";
    } else if (this.editOrder.search_address_id != null) {
      this.selected = "A";
    }
  },
  mounted() {
    this.$root.copy_order_modal = this.$refs.copy_order;
    this.currentDay = new Date(new Date().getTime() - 86400000);
  },
  methods: {
    callDriver() {
      if (this.$store.state.caller.status) {
        let initiatorNumber = JSON.parse(
          localStorage.getItem("sipConfig")
        ).user;
        this.$http
          .post("/connect-driver-operator", {
            number: initiatorNumber,
            phone: this.editOrder.performer.phone,
          })
          .then(() => {
            this.$store.commit("caller/UPDATE_IS_INITIATOR_STATE", true);
            this.$toast({
              component: ToastificationContent,
              position: "top-right",
              props: {
                title: "Запрос на соединение отправлен",
                icon: "XIcon",
                variant: "success",
                text: "",
              },
            });
          })
          .catch((err) => {
            this.$toast({
              component: ToastificationContent,
              position: "top-right",
              props: {
                title: "Ошибка",
                icon: "XIcon",
                variant: "danger",
                text: err,
              },
            });
          });
      } else {
        this.$toast({
          component: ToastificationContent,
          position: "top-right",
          props: {
            title: "Включите SIP телефонию",
            icon: "XIcon",
            variant: "danger",
            text: "",
          },
        });
      }
    },
    getTariffInfoModal() {
      this.$http
        .get(`/orders/tariff-info/${this.newOrder.tariff_id}`)
        .then((res) => {
          this.tariff_info = res.data;
        });
    },
    disabledDates(date) {
      return date < this.currentDay;
    },
    disabledTimes(date) {
      return date < new Date(new Date().getTime() + 900000);
    },
    changeTariff() {
      this.$http
        .get(`orders/allowances`, {
          params: { tariff_id: this.newOrder.tariff_id },
        })
        .then((res) => {
          this.allowances = res.data;
        });
      this.newOrder.allowances = [];
      this.calcPrice();
      this.getTariffInfoModal();
    },
    callClient() {
      if (this.$store.state.caller.status) {
        let initiatorNumber = JSON.parse(
          localStorage.getItem("sipConfig")
        ).user;
        this.$http
          .post("/connect-driver-operator", {
            number: initiatorNumber,
            phone: this.editOrder.phone,
          })
          .then(() => {
            this.$store.commit("caller/UPDATE_IS_INITIATOR_STATE", true);
            this.$toast({
              component: ToastificationContent,
              position: "top-right",
              props: {
                title: "Запрос на соединение отправлен",
                icon: "XIcon",
                variant: "success",
                text: "",
              },
            });
          })
          .catch((err) => {
            this.$toast({
              component: ToastificationContent,
              position: "top-right",
              props: {
                title: "Ошибка",
                icon: "XIcon",
                variant: "danger",
                text: err,
              },
            });
          });
      } else {
        this.$toast({
          component: ToastificationContent,
          position: "top-right",
          props: {
            title: "Включите SIP телефонию",
            icon: "XIcon",
            variant: "danger",
            text: "",
          },
        });
      }
    },
    callClientToDopPhone() {
      if (this.$store.state.caller.status) {
        let initiatorNumber = JSON.parse(
          localStorage.getItem("sipConfig")
        ).user;
        this.$http
          .post("/connect-driver-operator", {
            number: initiatorNumber,
            phone: this.editOrder.dop_phone,
          })
          .then(() => {
            this.$store.commit("caller/UPDATE_IS_INITIATOR_STATE", true);
            this.$toast({
              component: ToastificationContent,
              position: "top-right",
              props: {
                title: "Запрос на соединение отправлен",
                icon: "XIcon",
                variant: "success",
                text: "",
              },
            });
          })
          .catch((err) => {
            this.$toast({
              component: ToastificationContent,
              position: "top-right",
              props: {
                title: "Ошибка",
                icon: "XIcon",
                variant: "danger",
                text: err,
              },
            });
          });
      } else {
        this.$toast({
          component: ToastificationContent,
          position: "top-right",
          props: {
            title: "Включите SIP телефонию",
            icon: "XIcon",
            variant: "danger",
            text: "",
          },
        });
      }
    },
    detectEndTime() {
      this.formTime.end_time = this.$moment().format("YYYY-MM-DD HH:mm:ss");
      this.$http
        .post("time-form", this.formTime)
        .then((res) => {
          console.log(res);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    detectStartTime() {
      this.formTime.form_name = "orderEditForm";
      this.formTime.start_time = this.$moment().format("YYYY-MM-DD HH:mm:ss");
    },
    getTariffs(id) {
      this.$http
        .get("orders/tariff-list", { params: { division_id: id } })
        .then((res) => {
          this.tariffsList = res.data;
          this.calcPrice();
        });
    },
    getData() {
      this.checked = this.editOrder.active_bonus ? true : false;
      this.getTariffs(this.editOrder.division_id);
      this.memoData = this.editOrder;
      this.$http
        .get(`orders/allowances?tariff_id=${this.editOrder.tariff_id}`)
        .then((res) => {
          this.allowances = res.data;
        });
      // this.$http
      // .post('directory/search-addresses')
      // .then(res => {
      //     this.defaultAddresses = res.data
      // })
      this.getTariffInfoModal();
    },
    GetAllowances(allowances) {
      this.editOrder.allowances = allowances;
      this.calcPrice();
    },
    changeTariff() {
      this.calcPrice();
    },
    calcPrice() {
      let calcData = {
        from_address: this.editOrder.from_address,
        to_addresses: this.editOrder.to_addresses,
        // allowances: this.editOrder.allowances,
        // search_address_id:
        //   this.editOrder.from_address && this.editOrder.from_address.id
        //     ? this.editOrder.from_address.id
        //     : undefined,
        tariff_id: this.editOrder.tariff_id,
        // to_addresses: [],
        // client_phone: undefined,
        division_id: this.editOrder.division_id,
        // order_type: this.editOrder.order_type_id,
        // active_bonus: this.checked ? 1 : 0,
      };
      // calcData.client_phone = calcData.client_phone ? calcData.client_phone : undefined
      //   calcData.allowances = JSON.stringify(calcData.allowances);
      //   this.editOrder.to_addresses.forEach((el) => {
      //     if (el) {
      //       calcData.to_addresses.push({ search_address_id: el.id });
      //     }
      //   });
      //   calcData.to_addresses = JSON.stringify(calcData.to_addresses);
      console.log("efwefewef3");
      this.$http
        .post("calculate", calcData)
        .then((res) => {
          this.editOrder.price = res.data.amount;
          this.editOrder.distance = res.data.distance;
          this.editOrder.order_type_id = res.data.order_type;
          this.editOrder.bonus = res.data.bonus_price;
          this.amount_cash = res.data.amount_cash;
          this.client_login = res.data.client_login;
          this.client_bonus_balance = res.data.client_bonus_balance;
          this.newOrderData.distance_in_city = res.data.distance_in_city;
          this.newOrderData.free_km = res.data.free_km;
          this.newOrderData.distance_inter_city =
            res.data.distance - res.data.distance_in_city;
          this.newOrderData.distance_inter_city =
            this.newOrderData.distance_inter_city.toFixed(2);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    callAndConnect() {
      if (this.$store.state.caller.status) {
        this.$http
          .post("connect-client-performer", {
            number: this.editOrder.phone,
            phone: this.editOrder.performer.phone,
          })
          .then(() => {
            this.$toast({
              component: ToastificationContent,
              position: "top-right",
              props: {
                title: "Запрос на соединение отправлен",
                icon: "XIcon",
                variant: "success",
                text: "",
              },
            });
          })
          .catch((err) => {
            this.$toast({
              component: ToastificationContent,
              position: "top-right",
              props: {
                title: "Ошибка",
                icon: "XIcon",
                variant: "danger",
                text: err,
              },
            });
          });
      } else {
        this.$toast({
          component: ToastificationContent,
          position: "top-right",
          props: {
            title: "Включите SIP телефонию",
            icon: "XIcon",
            variant: "danger",
            text: "",
          },
        });
      }
    },
    openMemosForm() {
      this.$bvModal.show("memosCreateModal");
    },
    notIssued() {
      if (this.selected == "A") {
        this.editOrder.auto_assignment = 1;
        this.editOrder.not_issued = 0(
          this.editOrder.auto_assignment,
          this.editOrder.not_issued
        );
      } else if (this.selected == "B") {
        this.editOrder.auto_assignment = 0;
        this.editOrder.not_issued = 1(
          this.editOrder.auto_assignment,
          this.editOrder.not_issued
        );
      }
    },
    Intime() {
      this.editOrder.for_time = !this.editOrder.for_time;
    },
    addOrder() {
      if (this.submitButtonDisabled) return;
      let orderJSON = JSON.parse(JSON.stringify(this.editOrder));
      if (orderJSON.from_address) {
        orderJSON.search_address_id = orderJSON.from_address.id;
        orderJSON.from_address = undefined;
      } else {
        orderJSON.search_address_id = undefined;
      }
      orderJSON.to_addresses = [];
      for (let address of this.editOrder.to_addresses) {
        if (address.id) {
          orderJSON.to_addresses.push({ search_address_id: address.id });
        }
      }
      for (let i of orderJSON.allowances) {
        if (i.allowance_id == null) {
          i.allowance_id = undefined;
        }
        delete i.name;
      }
      orderJSON.number_of_passengers = undefined;
      orderJSON.to_addresses = JSON.stringify(orderJSON.to_addresses);
      orderJSON.allowances = JSON.stringify(orderJSON.allowances);
      this.submitButtonDisabled = true;
      this.$http
        .post("orders", orderJSON)
        .then((res) => {
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: "Успех!",
              icon: "CheckIcon",
              variant: "success",
              text: `Заказ успешно добавлен!`,
            },
          });
          this.titles = "Добавить заказ";
          this.$emit("refresh");
          this.$bvModal.hide("copy_order");
        })
        .catch((err) => {
          let error = err.response.data.errors;
          this.errorPhone = error.phone;
          for (let e in error) {
            var toastText = error[e][0];
          }
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: "Некорректные данные!",
              icon: "XIcon",
              variant: "danger",
              text: toastText,
            },
          });
        })
        .finally(() => {
          this.submitButtonDisabled = false;
        });
    },
    routePush() {
      this.editOrder.to_addresses.push(null);
      if (this.editOrder.to_addresses.length > 1) {
        this.disabled = false;
      } else {
        this.disabled = true;
      }
    },
    routeMinus(id) {
      let deleted = this.editOrder.to_addresses.splice(id, 1);
      if (this.editOrder.to_addresses.length > 1) {
        this.disabled = false;
      } else {
        this.disabled = true;
      }
      if (deleted[0].search_address) {
        this.calcPrice();
      }
    },
    spellDay(value) {
      if (value.length >= 5) {
        if (value > this.$moment().format("HH:mm")) {
          this.editOrder.date_time = this.$moment().format(
            `YYYY-MM-DD ${value}`
          );
        } else {
          this.editOrder.date_time = this.$moment()
            .add(1, "days")
            .format(`YYYY-MM-DD ${value}`);
        }
      }
    },
    ableDisableInfoModal() {
      this.info_modal = !this.info_modal;
    },
    toggleChecked() {
      this.checked = !this.checked;
      console.log(this.checked, "checked");
    },
  },
  computed: {
    hasPerformer() {
      if (this.editOrder.performer) {
        return true;
      }
      return false;
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";

@media (min-width: 992px) {
  .modal-lg {
    max-width: 1000px !important;
  }
}

@media (min-width: 576px) {
  .modal-dialog {
    max-width: auto !important;
  }
}

.list-group-item {
  transition: all 1s;
}

.vs__dropdown-menu {
  z-index: 9999 !important;
}

/* width */
.address::-webkit-scrollbar {
  width: 8px;
}

/* Track */
.address::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px rgb(156, 155, 155);
  border-radius: 10px;
}

/* Handle */
.address::-webkit-scrollbar-thumb {
  background: rgba(150, 150, 150, 0.924);
  border-radius: 10px;
}

/* Handle on hover */
.address::-webkit-scrollbar-thumb:hover {
  background: rgb(166, 166, 169);
}

.info-modal__interior {
  position: fixed;
  z-index: 1000;
  top: 23px;
  left: 25px;
  max-width: 350px;
  min-height: 565px;
  background: rgb(255, 253, 253);
  padding: 19.6px;
  border-radius: 4px;
}

.info-modal__tariff {
  margin-top: 5px;
  font-weight: bold;
}

.info-modal__title {
  margin: 8px 0 8px 0;
  font-weight: bold;
  margin: 0 auto;
  min-width: 100%;
}
.info-modal__text {
  margin: 0 auto;
  margin: 8px 0 8px 0;
}
.info-modal__allowances {
  margin-top: 10px;
  line-height: 10px;
}

.order-content {
  margin-left: 30%;
}

@media (min-width: 1780px) {
  .info-modal__interior {
    left: 4%;
  }
  .order-content {
    margin-left: 25%;
  }
}
@media (min-width: 1580px) {
  .info-modal__interior {
    margin-left: 4%;
  }
  .order-content {
    margin-left: 15%;
  }
}
@media (max-width: 1580px) {
  .info-modal__interior {
    margin-left: 0%;
  }
  .order-content {
    margin-left: 200px;
  }
}
@media (max-width: 1450px) {
  .order-content {
    max-width: 920px;
  }
}
@media (max-width: 1359px) {
  .order-content {
    margin-left: inherit;
  }
}

@media (min-width: 1900px) {
  .info-modal__interior {
    max-width: 500px;
  }
}

@media (min-width: 1780px) {
  .info-modal__interior {
    left: 4%;
  }
}

@media (min-width: 1580px) {
  .info-modal__interior {
    margin-left: 4%;
  }
}

@media (max-width: 1580px) {
  .info-modal__interior {
    margin-left: 0%;
  }
}

.bonus {
  display: flex;
  justify-content: space-around;
  background: yellow;
  margin: 0;
  padding: 0;
}

.bonus p {
  margin: 0;
  padding: 0;
}
</style>
